export const ExperimentData = [
    {
        title:"Pick up that paint brush !! ",
        image:'',
        details:{
            title:"Pick up that paint brush !!",
            images:[],
            description:'',

        },
        id:1
    },
    {
        title:'Designing flyer and menu for my first client',
        image:'',
        details:{
            title:'',
            images:[],
            description:'',
        },
        id:2
    },
    {
        title:'A sketch a month',
        image:'',
        details:{
            title:'A sketch a month',
            images:[],
            description:'',

        },
        id:3
    }
]
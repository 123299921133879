import { createGlobalStyle} from 'styled-components';
export const GlobalStyle = createGlobalStyle`
    *{
        box-sizing:border-box;      
        margin:0;
        padding:0;
        // font-family: 'Archivo Black', sans-serif;
        font-family: 'Judson', serif;
        // font-family: 'Work Sans', sans-serif;
        // font-family: 'Crimson Text', serif;
    }

`;